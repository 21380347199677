.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    font-weight: bold;
    font-size: 250px;
  }

  h2 {
    font-weight: bold;
    font-size: 15px;
  }

  .flex {
    display: flex;
    margin-top: 10px;

    h1 {
      font-weight: bold;
      font-size: 25px;
      margin-right: 10px;
    }
  }
}
