.footerMain {
  width: 100%;
  height: 4%;
  width: 100%;
  margin-bottom: 0.05%;
  margin-top: 0.05%;
}

.footer-bg {
  background-color: #1564ad;
  font-family: sans-serif;
  font-weight: 200;
  border-radius: 50px;
}

.logoja {
  width: auto;
  height: 1.65rem;
  display: block;
}
