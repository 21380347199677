.calculator_xhiro {
  display: flex;
  flex-direction: row;
  width: 50%;
}

@media (max-width: 1450px) {
  .calculator_xhiro {
    width: 100%;
    margin-top: 30px;
  }
}
