.cardFiskalizimi {
  margin: 10 !important;
  min-height: 470px;
  max-height: "auto";
}

.switch {
  margin-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.Item {
  display: flex;
  justify-content: space-between;
}
.Item strong {
  margin: 7px 7px 7px 0;
}
