.centered-element {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 10px); /* Adjust the width as needed */
}

.custom-dialog .p-resizable-handle {
  background-color: rgb(80, 121, 160);
}

.genero_button_div {
  justify-content: space-between;
  display: flex;
}

.datatable-cell {
  word-wrap: break-word; /* Break words that are too long */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
}
