.grupi-item {
  cursor: pointer;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0; /* Slight hover effect */
  }
}
