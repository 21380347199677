/* SmallButton.scss */
.mb-2 {
  margin-bottom: 8px;
}

.button-group {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.smallbutton-popup {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* SmallButton.scss */
.phone-text-smallbutton {
  font-size: 13px !important;
  margin-left: 4px;
}

@media (max-width: 800px) {
  .phone-text-smallbutton {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .phone-text-smallbutton {
    font-size: 13px !important;
    margin-left: 4px;
  }
}
