.main_div_inside_supermarket {
  width: 100% !important;
  height: 90% !important;
}

.rightbar_supermarket {
  width: 10%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #9eb8d9;
  margin-left: 1px;
  margin-right: 1px;
}

.faturat_supermarket {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  padding: 2%;
}

.id_supermarket {
  width: 100%;
  height: 5%;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.id_supermarket h2 {
  color: #000;
  margin: 2% 2% 0 2%;
  font-weight: bold;
}

.footer_supermarket {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #9eb8d9;
  margin: 1px;
}
