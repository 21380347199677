.dataTablePrimeGrid {
  height: 100%;
  width: 100%;
}

.custom-icon-red .pi {
  color: red !important; /* Use !important to ensure it overrides other styles */
}
.custom-icon-black .pi {
  color: rgb(
    0,
    0,
    0
  ) !important; /* Use !important to ensure it overrides other styles */
}

.p-datatable {
  height: 100%;
}

.p-contextmenu.p-component.p-contextmenu-enter-done {
  .p-menuitem.p-menuitem-active {
    background-color: rgb(211, 211, 211) !important;
  }
  .p-contextmenu.p-menuitem-link:hover {
    background-color: rgb(211, 211, 211) !important;
  }

  .p-menuitem-text {
    color: rgb(65, 65, 65) !important;
  }
  .pi-file {
    color: rgb(65, 65, 65) !important ;
  }
  .p-menuitem-link:hover {
    background-color: aliceblue !important;
  }
}
.p-contextmenu.p-component.p-contextmenu-enter-active {
  .p-menuitem-text {
    color: rgb(65, 65, 65) !important;
  }

  .pi-file {
    color: rgb(65, 65, 65) !important ;
  }
  .p-menuitem-link:hover {
    background-color: aliceblue !important;
  }
}

.p-datatable .p-datatable-header {
  height: 10%;
}

.p-datatable .p-datatable-wrapper {
  max-height: 92%;
}

.file-container {
  display: flex;
  justify-content: flex-end;
}

// .file-choosen {
//   width: 20rem;

//   @media (max-width: 1280px) {
//     width: 13rem;
//   }
// }

.data-table-disabled {
  pointer-events: none;
  opacity: 0.8;
}

.row-pointer {
  cursor: pointer;
}

// brenda main DataTables
// .p-datatable-table {
//   height: 100%;
// }
.p-datatable-table .p-datatable-thead {
  height: 7%;
}
.p-datatable-table .p-datatable-thead .extra_column {
  height: 100%;
}
.p-datatable-table .p-datatable-thead tr th {
  height: 100%;
}
.p-datatable-table .p-datatable-tbody {
  height: 85%;
  max-height: 88%;
}
.p-datatable-table .p-datatable-tfoot {
  height: 5%;
}

/* Styles for mobile devices */
@media (max-width: 1450px) {
  .p-datatable-table {
    height: 100%;
  }

  .p-datatable .p-datatable-header {
    height: 20%;
  }

  .p-datatable .p-datatable-wrapper {
    max-height: 80%;
  }

  .p-datatable-table .p-datatable-thead {
    height: 20%;
  }
  .p-datatable-table .p-datatable-thead .extra_column {
    height: 100%;
  }
  .p-datatable-table .p-datatable-thead tr th {
    height: 100%;
  }
  .p-datatable-table .p-datatable-tbody {
    height: 77%;
    max-height: 80%;
  }
  .p-datatable-table .p-datatable-tfoot {
    height: 5%;
  }
}
