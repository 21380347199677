.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Adjust the max-width for each column as needed */
.Id,
.Tavolina,
.Vlera,
.Operator {
  max-width: 100px; /* Example: Set a maximum width for the columns */
}

.datatable_faturat_main {
  height: 100%;
}

.datatable_faturat_inside_main {
  height: 100%;
}

/* Default styles */
.datatable_faturat_inside_main .p-datatable-header {
  height: 14%;
}

.datatable_faturat_inside_main .p-datatable-wrapper {
  height: 86%;
}

.p-dialog-header {
  height: 10%;
}

.p-dialog-content {
  height: 90%;
}

/* Responsive styles for viewport width 1450px */
@media (max-width: 1450px) {
  .datatable_faturat_inside_main .p-datatable-header {
    height: 40%;
  }

  .datatable_faturat_inside_main .p-datatable-wrapper {
    height: 60%;
  }
  /* Adjust the max-width for each column as needed */
  .Id,
  .Tavolina,
  .Vlera,
  .Operator,
  .KLFU_Kodi,
  .Kodi {
    max-width: 60px; /* Example: Set a maximum width for the columns */
  }

  .Data {
    max-width: 80px;
  }
}

.button_header_datatable_shikoFaturat {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.button_header_datatable_shikoFaturat2 {
  display: flex;
  // justify-content: space-between;
  // width: 40%;
}
