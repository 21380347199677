.tabela_supermarket {
  width: 70%;
  height: 100%;
}

.wrap_div_dataTable {
  height: 100%;
  border-radius: 10px;
  border: 1px solid #9eb8d9;
  margin-left: 1px;
}
