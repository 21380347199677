.tabela_artikuj {
  width: 20%;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #9eb8d9;
  margin-left: 1px;
}

.tabela_artikuj .center-content {
  margin: 0 auto;
}

.search_supermarket {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Pushes footer to the bottom */
}

.footer_keyboard_supermarket {
  display: flex;
  color: #ffffff;
  font-weight: bold;
  align-items: center;
  font-size: 18px;
  justify-content: flex-start;
}
