.template {
  height: 91%;
  margin-bottom: 0.05%;
  margin-top: 0.05%;
  background-color: #f8f9fa;
}

/* Styles for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .template {
    height: 100%;
  }
}

.header_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  background-color: var(--main-dashb-bg-color);
  padding: 0;
  border: 1px solid var(--border-template);
  height: 15%;
}

.p-toggleable-content {
  height: 85%;
}

.p-panel-content {
  height: 100%;
  // color: var(--txt-template-ftb) !important;
  background-color: var(--p-panel-content) !important;
}

.header_container {
  button {
    span {
      &.pi {
        &.pi-chevron-down,
        &.pi-chevron-up {
          margin-left: 5px;
        }
      }
    }
  }
}

.tittle_header {
  padding-left: 10px;
  font-weight: bold;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.buttons_right_header {
  margin-left: auto;
  padding: 1px;
}

.hover-text {
  margin-left: 5px;
  padding: 0 5px 0 5px;
  color: #fff;
  background-color: rgb(21, 100, 173);
  border-radius: 5px;
}

// .hover-text::after {
//   content: "Ndertuesi i dokumentit";
//   visibility: hidden;
//   opacity: 0;
//   transition: opacity 0.3s;
//   position: absolute;
//   background-color: black;
//   color: white;
//   padding: 5px;
//   border-radius: 3px;
//   font-size: 12px;
//   white-space: nowrap;
// }

// .hover-text:hover::after {
//   visibility: visible;
//   opacity: 1;
// }
