@media print {
  .print {
    display: block !important;
    min-width: fit-content !important;
  }

  .columnsSkontrino58 {
    font-size: 11px !important;
    color: #000;
  }

  hr {
    border-top: 1px dashed rgba(0, 0, 0, 0.712);
  }
  .tableTheadTvsh {
    text-align: center !important;
    align-items: center !important;
  }
  .tableTrTvsh {
    text-align: center !important;
    align-items: center !important;
  }
  .tableThTvsh {
    font-size: 8px !important;
    text-align: center !important;
    align-items: center !important;
  }
  .table2 {
    width: 5rem !important;
  }
  .nslf_58 {
    font-size: 8px !important;
  }
  .hide-border {
    border: 1px solid #ddd0 !important;
  }

  .columns {
    font-size: 10px !important;
    text-align: right !important;
  }

  .pershkrim {
    font-size: 8px !important;
  }
  th {
    font-size: 10px !important;
  }

  .footer-gjeneruar {
    font-size: 8px !important;
  }

  tr {
    text-align: left !important;
    font-size: 10px !important;
  }
  .tbody-print tr {
    text-align: right !important;
  }

  .solid-border {
    font-size: 10px !important;
    border: 0px solid #fff !important;
  }

  .footer {
    margin-bottom: 10px !important;
  }
  .border {
    border: 1px solid #fff !important;
  }
  .title {
    font-size: 10px !important;
  }
  tfoot td {
    text-align: center !important;
  }
  thead tr td {
    text-align: center !important;
  }
  .footer-bleresi {
    page-break-inside: avoid !important;
  }
  thead tr th {
    font-size: 10px !important;
  }
  td {
    font-size: 10px !important;
  }
  p {
    font-size: 10px !important;
  }
}
