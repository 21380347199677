.red-line {
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.little__div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 5%;
}

.dot,
.dotRed {
  height: 10px;
  width: 10px;
  background-color: #88d48a;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.dotRed {
  background-color: #f55c47;
}

.little__dsc {
  font-weight: bold;
  height: 30px;
  margin-right: 20px;
  display: inline-block;
  line-height: 30px;
  color: rgb(160, 160, 160);
}
