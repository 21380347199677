.sidebar {
  grid-column: 1;
  // padding: 0.5rem;
  // margin: 0.1rem;
  // border-radius: 0.75rem;
  width: 100%;
  background-color: var(--nav-bar-bg-color);
  // box-shadow: 0 1px 3px rgb(83, 83, 83);
  border-right: 1px solid white;
  display: block;
  position: relative; /* Default position */
  transition: all 0.3s ease; /* Smooth transition */
}

.logo_container {
  height: 6.7%;
  display: flex;
  text-align: left;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 20px;
}

/* When isMobile is true and drawerOpen is true, position sidebar absolutely */
.sidebar.mobile-open {
  position: absolute;
  top: 0;
  left: 0;
  width: 260px; /* Or any desired width */
  height: 100%; /* Full height */
  /* Sidebar background color */
  z-index: 9999; /* Ensure it overlays content */
}

.sidebar.hidden {
  display: none; /* Hide the sidebar when drawerOpen is false */
}

.sidebar.hidden {
  display: none; /* Hide the sidebar */
}

a {
  color: #ffffff !important;
  font-weight: 500;
  font-family: Arial, Helvetica, sans-serif;
}

.p-panelmenu .p-panelmenu-header a {
  background-color: var(--nav-bar-bg-color) !important;
  border: none !important;
}

.p-component .p-panelmenu-header .p-panelmenu-header-link:hover {
  background: var(--p-panelmenu-content-hover) !important;
}

// .p-menuitem-text {
//   font-size: 16px;
// }

.p-menuitem-text {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  color: var(--sidebar-color) !important;
}

.p-scrollpanel-content {
  // margin-left: 15px;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}

.p-menuitem-link:hover {
  background: var(--p-panelmenu-content-hover) !important;
}

.pi-file {
  color: rgb(255, 255, 255) !important;
}
.pi-angle-down::before {
  color: rgb(255, 255, 255) !important;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0rem !important;
}
.pi-chevron-right {
  color: rgb(255, 255, 255) !important;
}
.p-panelmenu .p-panelmenu-content {
  border: none !important;
}
.p-component .p-panelmenu-header {
  // height: 50px;
  width: 100%;
  padding: 0.3rem;
}
.p-component .p-scrollpanel-wrapper .p-scrollpanel-content {
  padding: 0 !important;
}
.p-component .p-panelmenu-header a {
  height: 50px;
  padding: 0.3rem;
}

.p-panelmenu .p-panelmenu-panel {
  background: none !important;
}

.p-panelmenu .p-panelmenu-content {
  background: var(--p-panelmenu-content) !important;
  border-radius: 0.6rem !important;
}

.p-menuitem-text {
  color: #fff !important;
}

.p-panelmenu .p-panelmenu-header a {
  padding: 0.7rem !important;
  font-weight: 500 !important;
  font-size: 15px;
  border-radius: 2px !important;
}

.p-tabview-title {
  color: #535353 !important;
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: var(--nav-bar-bg-color) !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

.p-inputtext.p-component {
  border-radius: 6px !important;
}

// Phone SideBar
.sidebar_phone {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  width: 260px;
  background-color: var(--nav-bar-bg-color);
  border-right: 1px solid white;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}
.sidebar_phone.open {
  transform: translateX(0);
}

.top-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navbar-logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.6rem;
  }

  .close-button {
    background: transparent;
    border: none;
    padding: 1rem;
    cursor: pointer;
    svg {
      width: 35px;
      height: 35px;
      color: white;
    }
  }
}

.sidebar-settings {
  z-index: 10;
  display: flex;
  position: fixed;
  width: 90%;
  bottom: 2rem;
  flex-direction: column;
  margin-top: 1rem;
  color: white;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 23px;
  gap: 1rem;

  .p-dialog {
    max-width: 90vw;
    width: auto !important;

    @media (min-width: 768px) {
      max-width: 70vw;
    }
  }

  .item {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 1rem;
    z-index: 40;
  }
}

.bold-item .p-menuitem-text {
  font-size: 18px !important;
  font-weight: 600 !important;
}

@media (max-width: 650px) {
  /* Adjust the breakpoint as needed */
  .p-component .p-panelmenu-header {
    height: 30px;
    padding: 0.1rem;
  }

  .p-component .p-panelmenu-header a {
    height: 30px;
    padding: 0.1rem;
  }

  .p-scrollpanel-content {
    margin-left: 15px;
    width: 100% !important;
    height: 50% !important;
    // overflow: hidden !important;
  }
}
