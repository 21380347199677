.buttonat_supermarket {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
}

.buttonat_supermarket Button {
  margin: 10px;
  height: 50px;
}
