.print_div {
  margin: 20px;
  border: 1px solid rgb(219, 219, 219);
}

.dashed-line {
  border-top: 1px dashed black;
  width: 100%;
  margin: 0;
}

.line {
  border-top: 1px solid black;
  width: 100%;
  margin: 0;
}

hr {
  border-top: 1px dashed rgba(0, 0, 0, 0.712);
}

.columns {
  font-size: 14px;
  text-align: right;
}

.tr_header {
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.title1 {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.tds-footer {
  font-size: 15px;
  text-align: left;
  font-weight: bold;
}

.columnsTotal {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}

.columnsTotalVlera {
  font-size: 20px;
  text-align: right;
  font-weight: bold;
}

.columnsSkontrino {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}

.columnsFis {
  font-size: 10px;
  text-align: left;
  font-weight: bold;
}

.pershkrim {
  font-size: 13px;
}

th {
  font-size: 13px;
}

tr {
  text-align: left;
  font-size: 13px;
}

thead tr th {
  font-size: 13px;
}

td {
  font-size: 13px;
}

p {
  font-size: 13px;
}
thead {
  display: table-row-group;
}

.scrollable-tbody {
  display: block;
  max-height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
  width: 100%;
}

.scrollable-tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.footer_text {
  margin-top: 20px;
  width: 100%;
  font-size: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
}
