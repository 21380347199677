.print {
  display: none !important;
  min-width: fit-content !important;
  padding: 1rem !important;
}

@media print {
  body {
    overflow: visible !important;
  }

  .print {
    display: block !important;
    min-width: fit-content !important;
    padding: 1rem !important;
  }

  .left-align {
    text-align: left;
  }

  .line-hr {
    border: 1px solid #000 !important;
  }
  .border-true {
    border: 1px solid #000 !important;
  }
  .funtior_table_head {
    display: flex !important;
    justify-content: space-between !important;
  }
  .funtior_table_head_below {
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    width: 100% !important;
  }
  .first_flex_kutiz {
    display: flex !important;
    flex-direction: row !important;
    width: 100% !important;
  }

  .title1 {
    text-align: center !important;
  }

  .kutiza_madhe_dy {
    display: flex !important;
    width: 100% !important;
    border-left: 1px none !important;
  }

  .kutiza_par {
    width: 60.2% !important;
    border-bottom: none !important;
    height: 70px !important;
  }

  .kutiza_par_2 {
    width: 60.2% !important;
    border-bottom: none !important;
    border-left: none !important;
    height: 70px !important;
  }

  .kutiza_madhe_tre {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    height: 70px !important;
    border-bottom: 1px none !important;
  }

  .kutiza_e_katert {
    margin-bottom: 0 !important;
  }

  .kutiza_dyte {
    width: 20% !important;
  }

  .kutiza_qr_code {
    padding: 2px !important;
  }

  .borderi_par {
    width: 49.3% !important;
  }

  .borderi_dyt {
    width: 50.7% !important;
  }

  .borderi_comment {
    width: 50% !important;
  }
  .borderi_comment_dyt {
    width: 50% !important;
  }

  .HeaderTable {
    margin-top: 10px !important;
  }
  .td_bleresi {
    text-align: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 4px;
    width: 200px !important;
  }
  .td_shitesi {
    text-align: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 4px;
    width: 200px !important;
  }

  .footer-gjeneruar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 10px;
  }

  .comment_value {
    text-align: center !important;
    height: 40px !important;
  }

  .table2 {
    width: 5rem !important;
  }
  tr {
    text-align: left !important;
    font-size: 12px !important;
  }
  .tbody-print tr {
    text-align: right !important;
  }

  .solid-border {
    border: 0px none #fff !important;
    text-align: left !important;
    padding-left: 4px !important;
  }

  .footer {
    margin-bottom: 10px !important;
  }
  .border {
    border: 1px solid #fff !important;
  }
  .title {
    font-size: 12px !important;
  }

  .title_printuar {
    margin-bottom: 10px !important;
  }

  tfoot td {
    text-align: center !important;
  }
  thead tr td {
    text-align: center !important;
  }
  .footer-bleresi {
    page-break-inside: avoid !important;
  }
  thead tr th {
    font-size: 10px !important;
  }
  td {
    font-size: 10px !important;
  }
  p {
    font-size: 10px !important;
  }
  table.table-body {
    // page-break-before: always;
    border-collapse: collapse;
    border-spacing: 0;
  }

  table.table-body thead {
    display: table-header-group;
  }

  .table_footer_blerje {
    width: 250px !important;
  }

  .tr_transportues {
    margin-top: 14px !important;
    padding-bottom: 2px !important;
  }

  .transportuesi {
    width: 250px !important;
  }

  .table_tvsh {
    width: 250px !important;
  }
}
