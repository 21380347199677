.main-container {
  overflow-x: hidden;
  height: 100%;
  color: var(--txt-template-ftb) !important;
  background-color: var(--main-dashb-bg-color) !important;
}

// .mainDiv_ftsh {
//   height: 100%;
//   overflow: hidden;
// }

.mainDiv_ftsh {
  display: grid;
  grid-template-columns: 260px 1fr; /* Default: Sidebar 260px, right column takes remaining space */
  height: 100%; /* Full viewport height */
  transition: grid-template-columns 0.3s ease; /* Smooth transition for the sidebar width change */
}

/* When drawer is closed, set the sidebar to 0 width */
.mainDiv_ftsh.drawer-closed {
  grid-template-columns: 0px 1fr; /* Sidebar hidden */
}
/* Sidebar on the left */
// .sidebar {
//   grid-column: 1; /* Places it in the first column */
//   background-color: #f0f0f0; /* Example background */
//   height: 100%; /* Full height */
// }

/* Right column */
.right-column {
  grid-column: 2; /* Places it in the second column */
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  height: 100%; /* Full height */
  overflow: hidden;
}

/* Navbar at the top of the right column */
// .navBarMain {
//   flex: 0 0 auto; /* Fixed height */
//   height: 6.7%; /* Example height */
//   // background-color: #e3e3e3; /* Example background */
// }

/* Template in the middle of the right column */
.template {
  flex: 1 1 auto; /* Flexible height */
  // background-color: #fff; /* Example background */
  overflow-y: auto; /* Enable scrolling if content overflows */
}

// .template.with-opacity {
//   opacity: 0.7;
//   background-color: #303336d2;
// }

.main-container.with-opacity {
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.082);
}

.p-toggleable-content.with-opacity {
  opacity: 0.5;
  background-color: rgb(0, 0, 0);
}

.dataTable_fsh.with-opacity {
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.938);
}

// /* Footer at the bottom of the right column */
// .footerMain {
//   flex: 0 0 auto; /* Fixed height */
//   height: 10%; /* Example height */
//   background-color: #d9d9d9; /* Example background */
// }

.top_panel_fsh {
  height: 34%;
}

.p-panel-header {
  height: 7%;
}

.middle_fsh {
  height: 4%;
}

.dataTable_fsh {
  height: 62%;
}

.sidebar {
  width: 100%;
}

.paneli-tabeve {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

// trre klasifikimet e responsit te parat
.paneli-tabeve .paneli-tabeve1 {
  width: 30%;
}

.paneli-tabeve .paneli-tabeve2 {
  width: 35%;
}

.paneli-fis-print {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styles for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .mainDiv_ftsh {
    height: 100%;
    width: 100%;
    // overflow: hidden;
  }
  .mainDiv_ftsh {
    grid-template-columns: 0px 1fr; /* Sidebar hidden */
  }

  .top_panel_fsh {
    height: 61%;
  }

  .middle_fsh {
    height: 4%;
  }

  .dataTable_fsh {
    height: 30%;
  }
}

@media (max-width: 1600px) {
  .paneli-tabeve .paneli-tabeve1 {
    width: 40%;
  }

  .paneli-tabeve .paneli-tabeve2 {
    width: 40%;
  }

  .paneli-fis-print {
    width: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.below-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}
// trre klasifikimet e responsit te dytat
.below-tabs .below-tabs1 {
  width: 30%;
}

.below-tabs .below-tabs2 {
  width: 35%;
}
.below-tabs .below-tabs3 {
  width: 25%;
}

@media (max-width: 1600px) {
  .below-tabs .below-tabs1 {
    width: 40%;
  }
  .below-tabs .below-tabs2 {
    width: 40%;
  }
  .below-tabs .below-tabs3 {
    width: 15%;
  }
}

.flex-on-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
