/* NavBarSuperMarket.scss */

.nav_supermarket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #9eb8d9;
  margin: 1px;

  .operatori_name {
    display: flex;
    align-items: center; /* Center vertically */
    margin-left: 10px;

    h2 {
      color: #000;
      margin: 10px 10px 0 10px;

      font-weight: 500;
    }
  }

  .buttons_right {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .btn_1_supermarket_nav {
    margin-right: 10px;
  }
}
