.mainComponent_fature_thjeshtuar {
  overflow: hidden;
  height: 94%;
  width: 100%;
  display: flex;
}

.main_fature_thjeshtuar {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header_fature_thjeshtuar {
  display: flex;
  height: 55%;
  width: 100%;
}

.table_fature_thjeshtuar {
  height: 45%;
  width: 100%;
}

.grupet_fature_thjeshtuar {
  width: 11%;
  height: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  overflow-y: scroll;
  gap: 10px;
}

.artikuj_fature_thjeshtuar {
  width: 89%;
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
  gap: 10px;
}

.sidebar_btn_fature_thjeshtuar {
  width: 5%;
  background-color: #88c7afa1;
  height: 100%;
}

// .grid_fature_thjeshtuar {
//   width: 100%;
//   height: 100%;
//   background-color: #fff;
// }

.button_paguar_fature_thjeshtuar {
  width: 120px;
  margin-right: 20px;
  border-radius: 5px;
  border: 1px solid #000000a1;
  cursor: pointer;
  text-align: center;
  align-items: center;
  font-size: 15px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
  justify-content: center;
}

.button_paguar_fature_thjeshtuar:hover {
  background-color: #1462af;
}

.grid_fature_thjeshtuar {
  height: 100%;
}

.datatable_fature_thjeshtuar {
  background-color: #fff;
}

.datatable_fature_thjeshtuar .p-datatable-header {
  height: 15%; /* Fixed height for the header */
}

.datatable_fature_thjeshtuar .p-datatable-wrapper table {
  height: 100%; /* Fixed height for the header */
}

.datatable_fature_thjeshtuar .p-datatable-wrapper table thead tr {
  height: 10%; /* Fixed height for the header */
}
.datatable_fature_thjeshtuar .p-datatable-wrapper table tbody {
  height: 90%; /* Fixed height for the header */
}

.datatable_fature_thjeshtuar .p-datatable-header > div {
  align-items: center; /* Center content in the header */
}

.header_paguar_fature_thjeshtuar {
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  margin: 2px;
}

.paguar_fature_thjeshtuar {
  margin-top: 10px;
  margin-left: 10px;
  padding: 5px;
  width: 40%;
}

.card_shiko_faturat_Einvoice {
  margin: 0 !important;
  height: 100%;
  width: 100%;
  border: 1px solid #b7b7b7;
  border-radius: 0.5rem;
  padding: 2px;
}

/* Responsive styles for viewport width 1450px */
@media (max-width: 1450px) {
  .datatable_faturat_inside_main_Einvoice {
    height: 100%;
  }
  .datatable_faturat_inside_main_Einvoice .p-datatable-header {
    height: 25%;
  }

  .datatable_faturat_inside_main_Einvoice .p-datatable-thead {
    height: 10%;
  }

  .datatable_faturat_inside_main_Einvoice .p-datatable-wrapper {
    height: 75%;
  }
}
