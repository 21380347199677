.title_dialog_faturat {
  display: flex;
}

.dialog_bar_datable_print {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: "scroll";
  justify-content: "space-between";
}

.card_dialog_bar_table {
  height: 100%;
  width: 65%;
  overflow: auto;
}

.card_dialog_bar {
  height: 100%;
  width: 35%;
  overflow: auto;
}

.dialog_context {
  height: 60vh;
  display: flex;
}

@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .title_dialog_faturat {
    flex-direction: column;
  }
  .dialog_bar_datable_print {
    flex-direction: column;
  }
  .card_dialog_bar_table {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  .card_dialog_bar {
    height: 0%;
    width: 0%;
    // overflow: auto;
  }
  .dialog_context {
    height: 100%;
    display: flex;
  }
}
