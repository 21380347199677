.main_div_turni {
  overflow: hidden;
  display: flex;
  background-color: #eeebebda;
  height: 100%;
  width: 100%;
}

.top_div_turni {
  margin: 1px;
  border-radius: 5px;
  border: 1px solid rgba(173, 173, 173, 0.692);
  background-color: #eeebebda;
}

.data_table_main_div {
  width: 70%;
}

.below_component {
  display: flex;
  width: 30%;
}

.data_table_main_div_second {
  width: 70%;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid rgba(173, 173, 173, 0.692);
  background-color: #fff;
  overflow: auto;
}

.right_div_mbyllje_turni {
  width: 30%;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid rgba(173, 173, 173, 0.692);
  background-color: #eeebebda;
}

@media (max-width: 1450px) {
  .main_div_turni {
    flex-direction: column;
    overflow: auto;
  }
  .main_header_left {
    display: flex;
    flex-direction: column;
  }
  .data_table_main_div {
    width: 100%;
  }
  .below_component {
    width: 100%;
  }
  .data_table_main_div_second {
    width: 70%;
  }
  .right_div_mbyllje_turni {
    width: 30%;
  }
}
