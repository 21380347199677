.pershkrimArtikull {
  text-align: left; /* Aligns text to the start (left) */
  align-self: start; /* Ensures the alignment in a flex context */
  /* Other styles can stay the same */
}

.line-hr {
  border: 1px solid #000 !important;
}
.border-true {
  border: 1px solid #000 !important;
}
.funtior_table_head_download {
  display: flex !important;
  justify-content: space-between !important;
}
.funtior_table_head_below_download {
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  width: 100% !important;
}

.kutiza_madhe_download {
  display: flex !important;
  width: 100% !important;
  padding-bottom: 10px !important;
}

.kutiza_madhe_download .logo {
  width: 52.5% !important;
  height: 20% !important;
}

.kutiza_madhe_download .title {
  text-align: center !important;
  margin-bottom: 20px !important;
  justify-content: center !important; /* Centers the title text */
  width: 100% !important; /* Ensures the title takes up full width */
  display: block !important; /* Ensure it is displayed as a block element */
}

.kutiza_madhe_dy_download {
  display: flex !important;
  width: 90% !important;
  border-bottom: none !important;
}

.kutiza_madhe_dy_download_majtas,
.kutiza_madhe_dy_download_djathtas {
  // border-bottom: none !important;
  padding-bottom: 10px; /* Add space between text and bottom */
}

.kutiza_madhe_dy_download_majtas {
  width: 53% !important;
}

/* Remove only the bottom border */
.border-bottom-false {
  border-bottom: none !important; /* Remove bottom border */
  border-left: 1px solid #000 !important; /* Optional: Add left border */
  border-right: 1px solid #000 !important; /* Optional: Add right border */
  border-top: 1px solid #000 !important; /* Optional: Add top border */
}

.kutiza_madhe_dy_download_djathtas {
  width: 47% !important;
}

.kutiza_madhe_tre_download {
  display: flex !important;
  width: 100% !important;
}

.kutiza_madhe_tre_download_majtas {
  width: 53% !important;
}

/* Remove all borders and apply only right border */
.border-true-right {
  border-right: 1px solid #000 !important; /* Only border on the right */
}

.kutiza_madhe_tre_download_djathtas_second {
  width: 47% !important;
}

.kutiza_madhe_tre_download_majtas,
.kutiza_madhe_tre_download_djathtas_second {
  // border-bottom: none !important;
  padding-bottom: 10px; /* Add space between text and bottom */
}

.kutiza_qr_code_download {
  position: absolute; /* Makes the QR code container absolute */
  top: 0; /* Adjust the positioning as needed */
  right: 0; /* Adjust the positioning as needed */
  width: 150px; /* Adjust the width */
  height: 150px; /* Adjust the height */
  margin-top: 30px;
}

.qr-code-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the QR code scales nicely */
}
.borderi_par {
  width: 49.3% !important;
}

.borderi_dyt {
  width: 50.7% !important;
}

.HeaderTable_download {
  margin-top: 20px !important;
  width: 100% !important;
}
.td_bleresi {
  text-align: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 4px;
  width: 200px !important;
}
.td_shitesi {
  text-align: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 5px;
  padding: 4px;
  width: 200px !important;
}

.footer-gjeneruar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
}

.table2 {
  width: 5rem !important;
}

.tbody-print tr {
  text-align: right !important;
}

.solid-border {
  border: 0px none #fff !important;
  text-align: left !important;
  padding-left: 4px !important;
}

.footer {
  margin-bottom: 10px !important;
}
.border {
  border: 1px solid #fff !important;
}
.title {
  font-size: 12px !important;
}

.title_printuar {
  margin-bottom: 10px !important;
}

.footer-bleresi {
  page-break-inside: avoid !important;
}

table.table-body {
  // page-break-before: always;
  border-collapse: collapse;
  border-spacing: 0;
}

table.table-body thead {
  display: table-header-group;
}

.table_footer_blerje {
  width: 250px !important;
}

.table_tvsh {
  width: 250px !important;
  border-collapse: collapse !important;
}
.table_tvsh th,
.table_tvsh td {
  border: 1px solid black !important; /* Add a 1px solid border to table cells */
  padding: 8px !important; /* Add some padding for readability */
  text-align: left !important; /* Align text to the left */
}

.table_tvsh th {
  background-color: #f4f4f4 !important; /* Add a background color to table headers */
  font-weight: bold !important; /* Make headers bold */
}

.table_tvsh tbody tr:nth-child(even) {
  background-color: #f9f9f9 !important; /* Add a light background for alternate rows */
}

.tr_transportues {
  margin-top: 14px !important;
  padding-bottom: 10px !important;
}

.transportuesi {
  width: 250px !important;
  padding-bottom: 10px !important;
}
