.template_konfig {
  display: grid;
  grid-template-columns: minmax(0, 250px) minmax(0, 1fr);
  height: 91%;
}

.sidebar_konfig {
  padding: 0.5rem;
  margin: 0.1rem;
  border-radius: 0.75rem;
  width: 100%;
  background-color: #d8d8d8;
  box-shadow: 0 1px 3px rgb(83, 83, 83);
  display: block;
}

.top_panel_konfig {
  height: 100%;
}

.item_nipt_tvsh {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.text_field_ndermarrje {
  width: 470px;
}

.button_group_fiskal {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

@media (max-width: 750px) {
  .text_field_ndermarrje {
    width: 320px;
  }

  .button_group_fiskal {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
