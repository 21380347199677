label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

.linku {
  color: #1a78d1 !important;
  font-weight: 500;
  text-decoration: none;
}

.linku:hover {
  color: #1a78d1 !important;
  text-decoration: underline;
  font-weight: 500;
}

.loginBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  span {
    font-size: 16px;
  }
}

.login_main_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  height: 100vh;
}
.login_logo {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: block;
  width: 104px;
  height: 110px;
}

.form {
  height: 45vh;
}

.card_login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: inherit;
  padding: 20px;
  height: 60vh;
  box-shadow: rgba(0, 0, 0, 0.178) 1px 1px 10px;
  border-radius: 6px;
  background-color: white;
  margin-top: 90px;
}

.text-center {
  color: #a6a6a6;
  margin-top: 30px;
}

.title_form {
  padding-bottom: 10vh;
  width: 30%;
  color: #000;
  font-weight: 300;
  font-size: 20px; /* Adjusted font size to 20px */
  text-align: left;
}

.footers {
  margin-top: auto;
  color: #a6a6a6;
  font-size: 12px !important;
}
