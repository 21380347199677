.rectangle1 {
  width: 100%;
  height: 100%;
  background: #ececec;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  overflow-y: hidden;
}

.rectangle2 {
  position: relative;
  width: 30%;
  height: 100%;
  background: #dbdbdbaf;
  border-radius: 10px;
  margin-right: 1%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  align-items: center;
}

.mainPoss {
  width: 70%;
  height: 100%;
}

hr.new5 {
  margin-top: 0%;
  border-top: 5px dotted rgba(47, 48, 47, 0.295);
}

// .sidebar_phone_main{
//   text-align: center;
//   align-items: center;
//   justify-content: center;
// }

.footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 2%;
  width: 95%;
  border-radius: 10px;
  height: 15%;
}

/* Sticky positioning for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .footer_phone_main {
    position: sticky;
    bottom: 0; /* Stick to the bottom of the viewport */
    z-index: 1000; /* Ensure it stays above other content */
    background-color: white; /* Optional: Ensure background color covers content beneath */
    margin-bottom: 0; /* Remove margin at the bottom */
    width: 100%; /* Full width on mobile */
    height: auto; /* Adjust height if necessary */
  }
}

.btn_Printo {
  width: 70%;
  height: 100%;
}

.btn_Keep {
  width: 25%;
  height: 100%;
}

.mainTop {
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  overflow-x: none;
  overflow-y: auto;
}

.mainBot {
  width: 100%;
  display: flex;
  overflow-x: none;
  overflow-y: auto;
}

// .hr_Buttons {
//   display: flex;
//   direction: row;
//   max-height: 10%;
// }

.num_length {
  color: rgb(0, 0, 0);
  padding-bottom: 0;
  border-radius: 40%;
}

.squares-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 95%;
  overflow-y: auto;
  position: relative;
  overflow: auto;
}

.header_Top {
  padding-bottom: 5px;
}

.menu_Top,
.search_Icon {
  font-weight: 800;
}

.square1 {
  margin-top: 1%;
  margin-left: 2%;
  width: 98%;
  min-height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.pershkrimArt_leftbar {
  margin-left: 1%;
  font-weight: 500;
  width: 12%;
  font-weight: 600;
}

.number_leftbar {
  width: 14%;
  font-weight: 600;
}

.shumezim_leftbar {
  width: 5%;
}

.cmimi_leftbar {
  flex-wrap: nowrap;
  width: 14%;
  font-weight: 600;
}

.stack_Buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 35%;
}

.stack_Buttons > Button {
  height: 35px;
  width: 30%;
  margin-right: 2%;
  background-color: #dbdbdbaf;
  border-radius: 5px;
}

.print_CmimiArt {
  font-weight: 600;
}

.hr_Buttons {
  display: flex;
  flex-wrap: nowrap;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.hr_pos {
  width: 100%;
  border-top: 3px dashed black;
}
.total_amount {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 1%;
}

.total h1 {
  font-weight: bold;
  font-size: 20px;
  height: 100%;
  width: 100%;
}

.amount h2 {
  font-weight: bold;
  font-size: 20px;
  height: 100%;
  width: 100%;
}

.background_button {
  padding: 3%;
  border-radius: 35px;
  background-color: black;
}

.button_div_pos {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  height: 95%;
  overflow-y: auto;
  position: relative;
  overflow: auto;
  height: 35px;
}

/* Default styles for the button */
.button_restaurant_Pos {
  width: 95% !important;
  left: 0 !important;
  top: 0 !important;
  align-self: start;
  color: #fff !important;
  background-color: #387adf !important;
  border-radius: 0 20px 20px 0 !important;
  margin-bottom: 3px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: 600 !important;
}

/* Default styles for the button */
.button_restaurant_Pos_disabled {
  width: 45% !important;
  left: 0 !important;
  top: 0 !important;
  align-self: start;
  color: #fff !important;
  background-color: #236ddb7c !important;
  border-radius: 0 20px 20px 0 !important;
  margin-bottom: 3px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: 600 !important;
}

/* Sticky positioning for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .button_restaurant_Pos {
    position: sticky !important;
    top: 0 !important; /* Stick to the top of the viewport */
    z-index: 1000 !important; /* Ensure it stays above other content */
    width: 100% !important; /* Full width on mobile */
    border-radius: 0 0 20px 20px !important; /* Optional: Adjust border radius if necessary */
  }

  .button_restaurant_Pos_disabled {
    position: sticky !important;
    top: 0 !important; /* Stick to the top of the viewport */
    z-index: 1000 !important; /* Ensure it stays above other content */
    width: 50% !important; /* Full width on mobile */
    border-radius: 0 0 20px 20px !important; /* Optional: Adjust border radius if necessary */
  }
}

/* Default styles for the button */
.button_restaurant_Pos_anulluese {
  width: 65% !important;
  left: 0 !important;
  top: 0 !important;
  align-self: start;
  color: #fff !important;
  background-color: #e40026 !important;
  border-radius: 0 20px 20px 0 !important;
  margin-bottom: 3px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: 600 !important;
}

/* Sticky positioning for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .button_restaurant_Pos_anulluese {
    position: sticky !important;
    top: 0 !important; /* Stick to the top of the viewport */
    z-index: 1000 !important; /* Ensure it stays above other content */
    width: 100% !important; /* Full width on mobile */
    border-radius: 0 0 20px 20px !important; /* Optional: Adjust border radius if necessary */
  }
}

.dataTable_Pos {
  position: absolute !important;
  width: 68.4% !important;
  right: 12.5px !important;
  bottom: 0 !important;
  background-color: #fff !important;
  border-radius: 20px 20px 0 0 !important;
}

.print_nmr_time {
  cursor: pointer;
  margin-bottom: 10px;
  width: 98%;
  align-self: flex-start;
}

.div_title_print_nmr {
  width: 95% !important;
  left: 0 !important;
  top: 0 !important;
  align-self: start;
  color: #fff !important;
  background-color: #387adf !important;
  border-radius: 0 20px 20px 0 !important;
  margin-bottom: 3px !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2) !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-weight: 600 !important;
}

.mobile_leftbar {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
