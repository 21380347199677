.buttons_fature_thjeshtuar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-top: 20px;
}

// Buttons component
.circle-button_fature_thjeshtuar {
  width: 70px;
  height: 70px;
  border-radius: 50%; /* Makes the button circular */
  border: none;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease; /* For hover effect */
}

.fiskal_fature_thjeshtuar {
  background-color: #ff9d3d;
}

.fiskal_fature_thjeshtuar:hover {
  background-color: #ad6200; /* Darken fiskal the button on hover */
}

.lista_fature_thjeshtuar {
  background-color: #f95454;
}

.lista_fature_thjeshtuar:hover {
  background-color: #a71818; /* Darken lista the button on hover */
}

.shto_btn_fature_thjeshtuar {
  background-color: #6a9c89;
}

.shto_btn_fature_thjeshtuar:hover {
  background-color: #637964; /* Darken shto_btn the button on hover */
}
