.print {
  display: none !important;
  min-width: fit-content !important;
}

@media print {
  .page {
    page-break-inside: avoid;
  }
  .page-break {
    page-break-before: always;
  }

  .theadMain {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .title1 {
    font-size: 20px !important;
    text-align: center !important;
    font-weight: bold !important;
  }

  .title1_urdhri {
    font-size: 16px !important;
    text-align: center !important;
    font-weight: bold !important;
  }

  .table_tvsh_fis_pos {
    width: 270px !important;
  }

  .hide-border {
    border: 1.5px solid rgba(0, 0, 0, 0) !important;
  }

  .dashed-line {
    border-top: 1px dashed #000 !important;
    width: 100%;
    color: #000;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .solid-line {
    border: 1.5px solid #000 !important;
    color: #000;
  }

  .div_line {
    width: 100%;
    height: 1px;
    background-color: #000;
    margin: 2px 0;
  }

  .line {
    font-weight: 500 !important;
    border-top: 2px solid black !important;
    color: #000 !important;
    width: 100% !important;
    margin: 0 !important;
  }

  hr {
    border-top: 1.5px dashed rgb(0, 0, 0);
    color: #000;
  }

  .columns {
    font-size: 11.5px !important;
    color: #000;
    text-align: right !important;
  }

  .columnsSkontrino_urdhri {
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #000;
    text-align: left !important;
  }

  .columnsSkontrino {
    font-weight: 500 !important;
    font-size: 11px !important;
    color: #000;
    text-align: left !important;
  }

  .columnsFis {
    font-weight: 400 !important;
    font-size: 11px !important;
    color: #000;
    text-align: left !important;
  }

  .columnsTotal {
    padding-top: 20px;
    font-size: 14px !important;
    color: #000;
  }

  .columnsPershkrim_urdhri {
    font-weight: 600 !important;
    font-size: 13px !important;
    color: #000;
    text-align: left !important;
  }

  .columnsPershkrim {
    font-weight: 500 !important;
    font-size: 11px !important;
    color: #000;
    text-align: left !important;
  }
  .columnsVlera {
    font-weight: 500 !important;
    font-size: 11px !important;
    color: #000;
    text-align: center !important;
  }
  .columnsvleracenterXhiroTurniPOS {
    text-align: right !important;
  }
  .columnsvleracenter {
    text-align: center !important;
  }

  .pershkrim {
    color: #000;
    font-size: 9.5px !important;
  }

  th {
    font-size: 12px !important;
  }

  tr {
    text-align: left !important;
    font-size: 12px !important;
    color: #000;
  }

  thead tr th {
    font-size: 12px !important;
  }

  td {
    font-size: 12px !important;
    color: #000;
  }

  p {
    font-size: 12px !important;
  }

  thead {
    display: table-row-group;
    color: #000;
  }
  .footer {
    width: 89% !important;
    text-align: center !important;
    height: 5% !important;
  }

  /* Hide footer after the first page */
  // .page-break + .footer {
  //   display: none !important;
  // }
  // .footer {
  //   position: fixed !important;
  //   top: auto !important;
  //   width: 89% !important;
  //   text-align: center !important;
  //   height: 5% !important;
  // }
}
