.template_konfig {
  display: grid;
  grid-template-columns: minmax(0, 250px) minmax(0, 1fr);
  height: 91%;
}

.sidebar_konfig {
  padding: 0.5rem;
  margin: 0.1rem;
  border-radius: 0.75rem;
  width: 100%;
  background-color: #d8d8d8;
  box-shadow: 0 1px 3px rgb(83, 83, 83);
  display: block;
}

.top_panel_konfig {
  height: 100%;
}

.item_nipt_tvsh {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.ndermarrje_dashboard {
  display: flex;
}

.text_field_ndermarrje {
  width: 470px;
}

.checkbox_ndermarrje {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.form_control_monedha {
  width: 250px;
}

@media (max-width: 750px) {
  .ndermarrje_dashboard {
    display: flex;
    flex-direction: column;
  }

  .text_field_ndermarrje {
    width: 320px;
  }

  .monedha_baze_checkbox {
    flex-direction: column;
    align-items: flex-start;
  }

  .form_control_monedha {
    width: 320px;
  }
}
