.inside_Art {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 5%;
}

.PershrkimArt {
  font-weight: 800;
}

.CmimiArt {
  font-weight: 600;
}
