.main-container-c {
  // overflow-x: hidden;
  height: 100%;
}

.mainDiv_ftsh-c {
  height: 100%;
  overflow: hidden;
}

.mainDiv_ftsh-c {
  display: grid;
  grid-template-columns: 260px 1fr; /* Default: Sidebar 260px, right column takes remaining space */
  height: 100%; /* Full viewport height */
  transition: grid-template-columns 0.3s ease; /* Smooth transition for the sidebar width change */
}

.main-container-c.with-opacity {
  opacity: 0.9;
  background-color: rgba(0, 0, 0, 0.082);
}

/* When drawer is closed, set the sidebar to 0 width */
.mainDiv_ftsh-c.drawer-closed {
  grid-template-columns: 0px 1fr; /* Sidebar hidden */
}

.top_panel_fsh-c {
  height: 35%;
}

.p-panel-header-c {
  height: 15%;
}

.middle_fsh-c {
  height: 3%;
}

.dataTable_fsh-c {
  height: 62%;
}

.sidebar-c {
  width: 100%;
}

.paneli-tabeve-c {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

// trre klasifikimet e responsit te parat
.paneli-tabeve-c .paneli-tabeve1-c {
  width: 30%;
}

.paneli-tabeve-c .paneli-tabeve2-c {
  width: 30%;
}

.paneli-fis-print-c {
  width: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1600px) {
  .paneli-tabeve-c .paneli-tabeve1-c {
    width: 45%;
  }

  .paneli-tabeve-c .paneli-tabeve2-c {
    width: 40%;
  }

  .paneli-fis-print-c {
    width: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.below-tabs-c {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}
// trre klasifikimet e responsit te dytat
.below-tabs-c .below-tabs1-c {
  width: 30%;
}

.below-tabs-c .below-tabs2-c {
  width: 30%;
}
.below-tabs-c .below-tabs3-c {
  width: 10%;
}

@media (max-width: 1600px) {
  .below-tabs-c .below-tabs1-c {
    width: 45%;
  }
  .below-tabs-c .below-tabs2-c {
    width: 40%;
  }
  .below-tabs-c .below-tabs3-c {
    width: 5%;
  }
}

.flex-on-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* Styles for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .mainDiv_ftsh-c {
    height: 100%;
    width: 100%;
    // overflow: scroll;
  }
  .mainDiv_ftsh-c {
    grid-template-columns: 0px 1fr; /* Sidebar hidden */
  }

  .top_panel_fsh-c {
    height: 62%;
  }

  .dataTable_fsh-c {
    height: 30%;
  }
}
