/* Default style */
// .template {
//   display: grid;
//   grid-template-columns: minmax(0, 250px) minmax(0, 1fr);
// }

// /* Style for screens 1450px or smaller */
// @media (max-width: 1450px) {
//   .template {
//     grid-template-columns: minmax(0, 1fr) minmax(0, 0px);
//   }
// }

.artikuj {
  cursor: pointer !important;
}

.card_artikuj1 {
  padding-bottom: 3%;
}

.card_artikuj1 .p-datatable-header {
  border: solid 1px #83818191 !important;
}

.btnLeftArtikuj {
  margin-right: 10px;
  margin-left: 10px;
}

/* Styles for mobile devices */
@media (max-width: 1450px) {
  .card_artikuj1 .p-datatable-table {
    height: 100%;
  }

  .card_artikuj1 .p-datatable .p-datatable-header {
    height: 10%;
  }

  .card_artikuj1 .p-datatable .p-datatable-wrapper {
    max-height: 80%;
  }

  .card_artikuj1 .p-datatable-table .p-datatable-thead {
    height: 10%;
  }
  .card_artikuj1 .p-datatable-table .p-datatable-thead .extra_column {
    height: 100%;
  }
  .card_artikuj1 .p-datatable-table .p-datatable-thead tr th {
    height: 100%;
  }
  .card_artikuj1 .p-datatable-table .p-datatable-tbody {
    height: 77%;
    max-height: 80%;
  }
  .card_artikuj1 .p-datatable-table .p-datatable-tfoot {
    height: 5%;
  }

  .btnLeftArtikuj {
    margin-right: 0px;
    margin-left: 0px;
  }
}
