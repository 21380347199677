.artikuj_flex_row {
  display: flex;
  flex-direction: row; /* Align items in a row */
  gap: 10px; /* Optional: space between items */
  flex-wrap: wrap; /* Optional: wrap items if they overflow */
}

.grupi-item {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  width: 150px; /* Set a minimum width for each item */
  text-align: center;
}
