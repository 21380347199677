@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --nav-bar-bg-color: #1564ad; /* Light mode color */
  --sidebar-bg-color: #fff;
  --sidebar-color: #000;
  --scroll-bar-color: #1564ad;
  --scroll-bar-bg-color: #fff;
  --main-dashb-bg-color: #fafafb;
  --tab-bg-color: #fff;
  --p-panelmenu-content: #1564ad;
  --p-panelmenu-content-hover: #b9bdc08e;
  --txt-template-ftb: #000;
  --p-panel-content: #ffffff;
  --border-template: rgba(161, 160, 160, 0.445);
  --unselected-row: #fff;
}

[data-theme="dark"] {
  --nav-bar-bg-color: #282c34; /* Define your desired color */
  --sidebar-bg-color: #282c34;
  --sidebar-color: #ffffff;
  --scroll-bar-color: #fff;
  --scroll-bar-bg-color: #282c34;
  --main-dashb-bg-color: #282c34;
  --tab-bg-color: #7d7c7c;
  --p-panelmenu-content: #000;
  --p-panelmenu-content-hover: #1563ada6;
  --txt-template-ftb: #fff;
  --p-panel-content: #7a7c80;
  --border-template: #fff;
  --unselected-row: #000;
}

// [data-theme="dark"] {
//   --nav-bar-bg-color: #282c34;
// }

.simplebar-scrollbar {
  background-color: var(--nav-bar-bg-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0;
  box-sizing: border-box;
}

/* Reset default margin and padding */
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Make #root take up 100% of viewport height and width */
#root {
  width: 100%;
  height: 100%; /* 100% viewport height */
}

.app {
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}

* {
  font-size: 10pt;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
  // scrollbar-color: var(--nav-bar-bg-color)
}

// Global template SideBar && Template

.css-azeo8y-MuiAvatar-root {
  background-color: transparent !important;
  border: solid 1px #bebebe;
}

table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  width: 100% !important;
  border: 1px solid #ddd !important;
}

.shenim {
  border: solid 1px #cee5ff;
  outline: none;
}

.shenim:hover {
  border: solid 1px #cee5ff;
}

.active-tabsLink {
  color: #248be6;
  border-bottom: solid 2px;
  padding: 1rem;
}

.p-column-title {
  font-weight: 500;
  font-size: 14px;
}

.p-datatable::-webkit-scrollbar {
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

.p-inputtext {
  height: 1.7rem !important;
  width: 100% !important;
}

.p-tabview-nav-content {
  display: flex !important;
  flex-direction: column !important;
}
.p-float-label label {
  margin-top: -0.4rem !important;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.81rem !important;
}
.p-float-label > label {
  font-size: 0.81rem !important;
}

// .p-datatable .p-datatable-header {
//   padding: 0.4rem !important;
// }

// .p-datatable-tbody {
//   min-height: 350px !important;
//   max-height: 300px !important;
// }
// .pi {
//   font-size: 0.7rem !important;
// }

.bar_kod {
  display: grid;
  grid-template-columns: 10% 65% 25%;
}
.klasifikime {
  background-color: white;
  color: #1971c2;
  font-weight: 500;
  left: 320px;
  top: -14px;
  letter-spacing: 2.5px;
}
.receptura {
  display: grid;
  grid-template-columns: 1.5% auto;
  gap: 1rem;
}
.procese {
  display: grid;
  grid-template-columns: 1.5% auto;
  gap: 1rem;
}

.footer-position {
  background-color: #1971c2;
  margin-top: auto;
  height: 3.5rem;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  bottom: 0.2rem;
  right: 0.5rem;
  left: 0.5rem;
  width: 100%;
}

.card {
  margin: 0 !important;
  height: 100%;
  width: 100%;
}

@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .card {
    margin: 0 !important;
    height: 100% !important;
    width: 100% !important;
  }
}

@media (max-width: 750px) {
  * {
    font-size: 8.5pt;
  }
}

.css-xsu6ln-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
}
// .p-dialog-content {
//   overflow: hidden !important;
// }

.shtim {
  z-index: 1000;
  background-color: #d6e9ff !important;
  transition: 0.8s;
}
.nxtPrv {
  z-index: 1000;
  background-color: #1c80cf !important;
  transition: 0.8s;
}

.layover {
  z-index: 40;
  width: 100%;
  height: 100vh;
  background-color: #49494967;
}

.pop_Check {
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #49494967;
}

.popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup-container h1 {
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.popup-container p {
  color: #555;
  margin-bottom: 20px;
}

.retry-button {
  background-color: #1564ad;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.bloc-tabs {
  display: flex;
}

.tabs {
  border-color: #5893f1 !important;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.active-tabs::before {
  content: "";
  display: block;
  border-color: #5893f1 !important;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: #5893f1;
}

/* Default border color */
.button_tab {
  color: #5893f1 !important;
  border-color: #5893f1 !important;
}

/* Border color when disabled */
.button_tab:disabled {
  color: #d3d3d3 !important;
  border-color: #d3d3d3 !important; /* Light gray border when disabled */
}

.content-tabs {
  flex-grow: 1;
}

.content {
  display: none;
}

.active-content {
  display: block;
}

.p-tabview-title {
  color: #23547b !important;
}

input[type="file"]::file-selector-button {
  border: 1px solid #1971c2;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  transition: 1s;
  color: #1564ad;
}

// input[type="file"]::file-selector-button:hover {
//   background-color: #4d90e7;
//   border: 1px solid #2aaaf5;
// }

.p-menuitem-icon {
  color: white !important;
}

// color background-color selected-row per each dataTable
.selected-row {
  color: white !important;
  background-color: rgba(75, 119, 148, 0.7607843137) !important;
}

.unselected-row {
  color: var(--sidebar-color) !important;
  background-color: var(--unselected-row) !important;
}
