.dialogActionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialogTitleContainer {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}
