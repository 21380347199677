.print {
  display: none !important;
  min-width: fit-content !important;
  padding: 1rem !important;
}

@media print {
  body {
    overflow: visible !important;
  }

  .print {
    display: block !important;
    min-width: fit-content !important;
    padding: 1rem !important;
  }

  .pershkrimArtikull {
    text-align: left; /* Aligns text to the start (left) */
    align-self: start; /* Ensures the alignment in a flex context */
    /* Other styles can stay the same */
  }

  .line-hr {
    border: 1px solid #000 !important;
  }
  .border-true {
    border: 1px solid #000 !important;
  }
  .funtior_table_head {
    width: 100% !important;
    display: flex !important;
    // justify-content: space-between !important;
  }
  .funtior_table_head_below {
    display: flex !important;
    // justify-content: space-between !important;
    flex-direction: column !important;
    width: 100% !important;
  }

  .kutiza_madhe {
    display: flex !important;
    width: 100% !important;
    padding-bottom: 5px;
    margin-top: 30px !important;
    margin-bottom: 20px !important;
  }

  .kutiza_madhe .logo {
    width: 52.5% !important;
  }

  .kutiza_madhe .title {
    align-self: center !important;
  }

  .kutiza_madhe_dy {
    display: flex !important;
    width: 100% !important;
  }

  .kutiza_pare {
    width: 50% !important;
  }

  .kutiza_dyte {
    width: 50% !important;
  }

  /* Remove all borders and apply only right border */
  .border-true-right {
    border-right: 1px solid #000 !important; /* Only border on the right */
  }

  // .kutiza_qr_code {
  //   width: 20% !important;
  //   padding-bottom: 4px !important;
  // }

  .kutiza_qr_code {
    position: absolute !important; /* Makes the QR code absolute */
    top: 0 !important; /* Adjust the top position as needed */
    right: 0 !important; /* Adjust the right position as needed */
    z-index: -1 !important; /* Prevents interaction with the other elements */
    width: 150px !important; /* Adjust the width of the QR code */
    height: 150px !important; /* Adjust the height of the QR code */
    margin-top: 30px;
  }

  /* Remove only the bottom border */
  .border-bottom-false {
    border-bottom: none !important; /* Remove bottom border */
    border-left: 1px solid #000 !important; /* Optional: Add left border */
    border-right: 1px solid #000 !important; /* Optional: Add right border */
    border-top: 1px solid #000 !important; /* Optional: Add top border */
  }

  .shenim-cell {
    font-weight: bold;
    line-height: 2; /* Increases row height */
  }

  .borderi_par {
    width: 49.3% !important;
  }

  .borderi_dyt {
    width: 50.7% !important;
  }

  .HeaderTable {
    margin-top: 10px !important;
  }
  .td_bleresi {
    text-align: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 4px;
    width: 200px !important;
  }
  .td_shitesi {
    text-align: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    padding: 4px;
    width: 200px !important;
  }

  .footer-gjeneruar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 10px;
  }

  .table2 {
    width: 5rem !important;
  }
  tr {
    text-align: left !important;
    font-size: 12px !important;
  }
  .tbody-print tr {
    text-align: right !important;
  }

  .solid-border {
    border: 0px none #fff !important;
    text-align: left !important;
    padding-left: 4px !important;
  }

  .footer {
    margin-bottom: 10px !important;
  }
  .border {
    border: 1px solid #fff !important;
  }
  .title {
    font-size: 12px !important;
  }

  .title_printuar {
    margin-bottom: 10px !important;
  }

  tfoot td {
    text-align: center !important;
  }
  thead tr td {
    text-align: center !important;
  }
  .footer-bleresi {
    page-break-inside: avoid !important;
  }
  thead tr th {
    font-size: 10px !important;
  }
  td {
    font-size: 10px !important;
  }
  p {
    font-size: 10px !important;
  }
  table.table-body {
    // page-break-before: always;
    border-collapse: collapse;
    border-spacing: 0;
  }

  table.table-body thead {
    display: table-header-group;
  }

  .table_footer_blerje {
    width: 250px !important;
  }

  .tr_transportues {
    margin-top: 14px !important;
    padding-bottom: 10px !important;
  }

  .transportuesi {
    width: 250px !important;
    padding-bottom: 10px !important;
  }

  .table_tvsh {
    width: 250px !important;
    border-collapse: collapse !important;
  }
  .table_tvsh th,
  .table_tvsh td {
    border: 1px solid black !important; /* Add a 1px solid border to table cells */
    padding: 8px !important; /* Add some padding for readability */
    text-align: left !important; /* Align text to the left */
  }

  .table_tvsh th {
    background-color: #f4f4f4 !important; /* Add a background color to table headers */
    font-weight: bold !important; /* Make headers bold */
  }

  .table_tvsh tbody tr:nth-child(even) {
    background-color: #f9f9f9 !important; /* Add a light background for alternate rows */
  }
}
