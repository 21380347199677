.drop_down_list {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.drop_down_list_kls {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 0.5rem;
}

.first-border-pergjithshme {
  width: 70%;
}

.second-border-pergjithshme {
  width: 30%;
}

.second_div {
  height: 85%;
}

.kutizat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 17%;
}

.kutizat_Shenim_Llogari {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25%;
}

.kutiza-fundit {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.kodi_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: black;
  justify-content: space-between;
  width: 50%;
  padding: 0.5rem;
}

.input_kodi {
  border: 1px solid #ddd;
  padding: 1rem;
}

.checkbox_box {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: black;
  justify-content: space-between;
  width: 50%;
}

.checkbox_artikuj {
  width: 25px;
  height: 25px;
  // border-radius: 5px;
  // border: 1px solid #7a7a7a94;
}

// .p-float-label {
//   height: 50%;
//   width: 100%;
// }

/* Adjust the max-width as needed for mobile view */
@media (max-width: 1450px) {
  .first-border-pergjithshme {
    width: 100%;
  }
  .second-border-pergjithshme {
    width: 100%;
    display: flex;
  }
  .kodi_box {
    gap: 5px;
  }

  .kutizat {
    width: 100%;
  }

  .kutizat_Shenim_Llogari {
    width: 100%;
  }

  .kutiza-fundit {
    width: 100%;
    gap: 10px;
  }
}
