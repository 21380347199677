.main_header_left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-left: 30px;
  width: 70%;
}

@media (max-width: 1450px) {
  .main_header_left {
    width: 100%;
    margin: 0;
    gap: 8px;
  }
}
