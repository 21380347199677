.sektor {
  padding-top: 10px;
  border-radius: 20px;
}

:hover.sektor {
  opacity: 0.8;
  background-color: #11999e;
}

// #active.sektor {
//   opacity: 1;
//   background-color: #42a4f4;
// }

.sektor p {
  color: white;
}

// .kthePAnelet {
//   height: 100%;
//   background-color: var(--kthePanelet-bg-color);
//   width: 100%;
// }

.kthePAnelet {
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
}

.theme-toggle {
  background-color: var(--button-color);
  &:hover {
    background-color: var(--button-hover-color);
  }
}

#navBariPos {
  display: flex;
  align-items: center;
  height: 5%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.tavolinat {
  width: 100px;
  height: 100px;
  background: #42a4f4;
  color: white;
  border-radius: 20px;
  opacity: 0.8;
  cursor: pointer;
}

:hover.tavolinat {
  opacity: 1;
  background: #42a4f4;
}

#tavolineHapur {
  background: #f52020;
}

#tavolineMbyllur {
  background: #42a4f4;
}

.tavolinat #nrtavolines {
  font-size: 30px;
}

.kategori {
  padding-top: 10px;
  border-radius: 20px;
}

:hover.kategori {
  opacity: 0.8;
  background-color: #42a4f4;
}

#active.kategori {
  opacity: 1;
  background-color: #42a4f4;
}

.navbarText {
  color: white;
  font-size: 15px;
  margin: 5px;
}

#th {
  color: white;
  font-size: 15px;
  font-weight: bolder;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #1564ad;
}

.inputnumber {
  border: 0;
  border-bottom: 1px solid #1564ad;
  text-align: center;
  width: 70px;
}

.tableinforow p {
  color: white;
  font-size: 15px;
  font-weight: bolder;
  padding-bottom: 10px;
  padding-top: 10px;
  background-color: #1564ad;
  position: fixed;
  bottom: 15px;
  margin-left: 100px;
  padding-left: 150px;
  padding-right: 150px;
}

.button-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1px;
}

.button-grid-container button {
  width: 100%;
  height: 100px; /* Set your desired height */
}

.title_table h1 {
  font-weight: bold;
}
.price_table {
  padding: 2px;
  background-color: #dee2e6;
  color: #000;
  border: 1px solid rgba(122, 94, 94, 0.418);
  border-radius: 10px;
}
