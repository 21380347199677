.p-dialog-header {
  height: 8%;
}
// .p-dialog-content {
//   height: 93%;
// }
.cardModal {
  height: 100%;
  width: 100%;
}

.datatable_faturat {
  height: 90%;
}

.datatable_faturat .p-datatable-wrapper {
  height: 85%;
}

.card_shiko_faturat {
  margin: 0 !important;
  height: 90%;
  width: 100%;
  border: 1px solid #b7b7b7;
  border-radius: 0.5rem;
}

.buttonsFaturat {
  height: 7%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.p-tabview {
  height: 100%;
  width: 100%;

  .p-tabview-nav-container {
    height: 10%;
    width: 100%;
  }

  .p-tabview-nav-content {
    height: 93%;
    width: 100%;
  }

  .p-tabview-panels {
    height: 90%;
    width: 100%;
    padding: 0 !important;
  }
}

.p-tabview-panel:first-child {
  height: 100%;
  width: 100%;
}

.card_artikuj {
  overflow: hidden;
  margin: 2px !important;
  height: 100% !important;
  width: 100% !important;
}
