.main_div_data_table_supermarket {
  height: 90%;
  border-radius: 10px;
}

.datatable_supermarket {
  height: 100%;
  border-radius: 10px;
}

.p-datatable-wrapper {
  height: 90%;
}

.prime-react {
  height: 100%;
  border-radius: 10px;
}

.p-datatable-table {
  height: 100%;
  background-color: #fff;
}
.p-datatable-thead {
  height: 5%;
}

.p-datatable-tbody {
  height: 90%;
}

.p-datatable-tfoot {
  height: 5%;
}
