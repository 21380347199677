//inside page navbar
html {
  scroll-behavior: smooth;
}
/* Default styles for the navbar */
.navBarMain {
  height: 6.7%;
  width: 100%;
  margin-bottom: 0.05%;
  border-bottom: 1px solid white;
  background-color: var(--nav-bar-bg-color);
  // margin-top: 0.05%;
  // border-radius: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Customize as needed
}

/* Sticky positioning for mobile devices */
@media (max-width: 1450px) {
  /* Adjust the breakpoint as needed */
  .navBarMain {
    position: sticky;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0px;
    background-color: var(--nav-bar-bg-color);
    top: 0; /* Stick to the top */
    z-index: 1000; /* Ensure it stays above other content */
  }
}

.tekst {
  letter-spacing: 0.5px;
  font-size: 15px;
}
.switch-accounts {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  color: rgb(107, 107, 107);
}

.navitem {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.9rem;
  margin: 0 10px 0 10px;
  border-radius: 0.375rem;
  transform: scale(1);
  transition: background-color 0.2s ease-in-out;
  letter-spacing: 0.5px;
  justify-content: space-between;
  font-size: 14px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.301);
  }
}

.logoja {
  display: flex;
  width: auto;
  height: 1.65rem;
  display: block;
  margin-left: 0.6rem;
}

.css-2dum1v-MuiDrawer-docked .MuiDrawer-paper {
  margin-right: 2rem !important;
}
.all__links {
  color: #fafafa !important;
  font-weight: 500;
}
.p-menubar {
  background-color: #1d71c0 !important;
}
.p-menubar ul {
  margin-left: 13rem !important;
}

.icons {
  opacity: 50%;
}

.item-1 {
  flex: 0 1 250px;
  min-width: 0;
}

.item-2 {
  flex: 1 1 0;
}

.navbar_pos_maindiv {
  width: 100%;
}

.navbar_table {
  width: 100%;
  height: 5%;
  align-items: center;
}

.pos_navbar {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.item-2 .item-2-ul {
  text-align: start;
  justify-content: flex-start;
}
.item-3 {
  width: 5%;
}
.item-4 {
  flex-grow: 1;
  justify-content: flex-end;
}

.links {
  background-color: #1971c2;
}

.links:hover {
  background-color: #0263be;
}
.navbar-landing {
  border-radius: 10px;
}
.navbari li {
  transition: all 0.3s;
  position: relative;
}

@media (max-width: 1450px) {
  .navbari {
    display: none !important;
  }
}

///outside navbar
.links-outside {
  display: block;
}

.warpper {
  display: flex;
  animation: slide 16s ease-in-out infinite;
}

.active {
  background-color: var(--p-panelmenu-content-hover);
  //  rgba(255, 255, 255, 0.301);
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;

  &:hover {
    background-color: #0056b3;
  }

  &.show-scroll-to-top {
    opacity: 1;
    pointer-events: auto;
  }

  svg {
    font-size: 28px;
  }
}

.menu_btn {
  background: transparent;
  border: none;
  padding: 1rem;
  cursor: pointer;
  svg {
    width: 32px;
    height: 32px;
    color: white;
  }
}

.nav-item {
  position: relative;
}

.has-dropdown {
  cursor: pointer;
}

.sublink-list {
  font-family: sans-serif;
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 115%;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust for perfect centering */
  background: #1d71c0; /* Background color for dropdown */
  border: 1px solid #ffffff65; /* Border color */
  border-radius: 4px;
  z-index: 1000;
}

.sublink-list li {
  margin: 4px;
  padding: 8px;
}

.sublink-list a {
  display: flex;
  align-items: center;
  color: white !important;
  text-decoration: none;
}

.sublink-list li:hover {
  border-radius: 0.375rem;
  background-color: rgba(255, 255, 255, 0.301);
}

.sublink-item {
  text-align: center;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
